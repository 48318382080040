// Libraries
import React, { useEffect } from 'react';
import { useRouter } from '@hum/icm-app/src/hooks/useRouter';
// Components
import {
  ConnectorType,
  CONNECTOR_LABELS,
  CurrentCompany,
} from '@hum/icm-app/src/state';
import { Welcome } from '@hum/icm-app/src/components/Welcome';
import { Spinner } from '@hum/ui-library';

import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import {
  externalServiceConnected,
  apiUserReviewSessionRequestSent,
} from '@hum/icm-app/src/actions';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';

const getDefaultCodeParams = (
  code: string,
  { scope }: Record<string, string>
) => ({
  code,
  scope,
});

// Screen designed to help a user connect oauth tokens
const OauthSuccessPage = (
  connectorType: ConnectorType,
  getOAuthCodeParams: (
    code: string,
    params: Record<string, string>
  ) => Record<string, string> = getDefaultCodeParams
) => {
  const ConnectorComponent = ({
    currentCompany,
  }: {
    currentCompany: CurrentCompany;
  }) => {
    const { dispatch, state } = useAppStore();
    const { id: companyId } = currentCompany || { id: null };
    const flags = useFlags();

    const {
      query: { code, ...paramsRest },
    } = useRouter();

    useEffect(() => {
      // FIXME: this can be moved into saga code.
      // This code also isn't reflecting a side-effect. See https://github.com/captec/capital/blob/master/frontend/docs/architecture.md#component-dos-and-donts
      if (!state.session.loaded) {
        dispatch(apiUserReviewSessionRequestSent({}));
      }
    }, []);

    useEffect(() => {
      if (companyId) {
        dispatch(
          externalServiceConnected({
            displayName: connectorType,
            companyId,
            connectorType,
            airbyteEnabled: flags.enabled('show-airbyte'),
            // @ts-ignore
            params: getOAuthCodeParams(code, paramsRest),
          })
        );
      }
    }, [companyId]);

    if (!companyId) {
      return <Spinner fullScreen />;
    }

    return (
      <Welcome
        title={`${CONNECTOR_LABELS[connectorType]} Connect`}
        subtitle="Connection successful!"
      >
        This window will close automatically in a few seconds...
      </Welcome>
    );
  };
  return ConnectorComponent;
};

export default OauthSuccessPage;
