import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const microsoftDynamicsDirectConnector: AvailableServiceInfo = {
  label: 'Microsoft Dynamics',
  icon: require('./microsoft_dynamics.svg'),
  isBeta: true,
  type: ConnectorType.MicrosoftDynamics,
  source: ConnectorSource.Capital,
  fields: [
    {
      label: 'Enter your username',
      propertyName: 'username',
    },
    {
      label: 'Enter your password',
      propertyName: 'password',
    },
    {
      label: 'Enter your Client ID',
      propertyName: 'client_id',
    },
    {
      label: 'Enter your Client SECRET',
      propertyName: 'client_secret',
    },
  ],
  connect: (params) => params,
};
