import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';

import { Spinner } from '@hum/ui-library';
import { Routes } from '@hum/icm-app/src/state';

import { ConnectFreshbooks } from './freshbooks';
import { ConnectPaypal } from './paypal';
import { ConnectQuickbooks } from './quickbooks';
import { ConnectShopify } from './shopify';
import { ShopifyInstallRedirect } from './shopify/install';
import { ConnectStripe } from './stripe';
import { ConnectSquare } from './square';
import { ConnectXero } from './xero';

export const ConnectPage = () => {
  const currentCompany = useCurrentCompany();

  if (!currentCompany.data) {
    return <Spinner fullScreen />;
  }

  return (
    <Switch>
      <Route path={Routes.CONNECT_FRESHBOOKS}>
        <ConnectFreshbooks currentCompany={currentCompany.data} />
      </Route>
      <Route path={Routes.CONNECT_PAYPAL}>
        <ConnectPaypal currentCompany={currentCompany.data} />
      </Route>
      <Route path={Routes.CONNECT_QUICKBOOKS}>
        <ConnectQuickbooks />
      </Route>
      <Route path={Routes.CONNECT_SHOPIFY}>
        <ConnectShopify currentCompany={currentCompany.data} />
      </Route>
      <Route path={Routes.CONNECT_SHOPIFY_INSTALL}>
        <ShopifyInstallRedirect />
      </Route>
      <Route path={Routes.CONNECT_SQUARE}>
        <ConnectSquare currentCompany={currentCompany.data} />
      </Route>
      <Route path={Routes.CONNECT_STRIPE}>
        <ConnectStripe currentCompany={currentCompany.data} />
      </Route>
      <Route path={Routes.CONNECT_XERO}>
        <ConnectXero currentCompany={currentCompany.data} />
      </Route>
    </Switch>
  );
};
