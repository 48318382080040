import { AvailableServiceInfo } from './base';
import { env } from '@hum/common';
import { openOAUthWindow2 } from '@hum/icm-app/src/utils';
import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { stringify } from 'query-string';

const sandboxClientId = 'sandbox-sq0idb-zOs-0PbjROlQRekENFO8mw';

//const cashDrawerRead = 'CASH_DRAWER_READ'
const paymentsRead = 'PAYMENTS_READ';

// this needs to not be encoded as it will transform the '+' into its encoded version , this causes a wrong scope error
const scope = `${paymentsRead}`;

const config = {
  client_id: env.NEXT_STATIC_SQUARE_CLIENT_ID || sandboxClientId,
  scope: scope,
};

const domain = env.NEXT_STATIC_SQUARE_DOMAIN || 'connect.squareupsandbox.com';
const path = env.NEXT_STATIC_SQUARE_AUTHZ_URL || '/oauth2/authorize';

export const squareOAuthConnector: AvailableServiceInfo = {
  label: 'Square',
  icon: require('./square.svg'),
  type: ConnectorType.Square,
  source: ConnectorSource.Capital,
  async connect() {
    const oauthUrl = `https://${domain}${path}?${stringify({
      ...config,
    })}`;
    return await openOAUthWindow2(oauthUrl);
  },
};
