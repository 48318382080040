import React from "react";
import _pubA97D53F3 from "../../../../../../design-system/src/atoms.pc";
import _pub2Dbb4493 from "../../../../../../design-system/src/Button.pc";
import _pubDf2A042 from "../../../../../../design-system/src/Field.pc";
import _pubC530B6D5, {ModalPreview as _pubC530B6D5_ModalPreview} from "../../../../../../design-system/src/Modal.pc";
import _pub5Ab99334 from "../../../../../../design-system/src/TextInput.pc";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Header = React.memo(React.forwardRef(function Header(props, ref) {
  return React.createElement("div", {
    "className": "_5d590f91 _7b80b09a _pub-7b80b09a",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Header };

var HeaderNav = React.memo(React.forwardRef(function HeaderNav(props, ref) {
  return React.createElement("div", {
    "className": "_bae12296 _7b80b09a _pub-7b80b09a" + (props["noBack"] ? " " + "_7b80b09a_no-back _pub-7b80b09a_no-back no-back" : ""),
    "ref": ref,
  }, 
    React.createElement("button", {
      "className": "_c2569eb3 _7b80b09a _pub-7b80b09a" + (props["hover"] ? " " + "_7b80b09a_hover _pub-7b80b09a_hover hover" : ""),
      "data-testid": props["backButtonTestId"],
      "onClick": props["onBackButtonClick"],
    }, null)
  )
}));
export { HeaderNav };

var Content = React.memo(React.forwardRef(function Content(props, ref) {
  return React.createElement("div", {
    "className": "_52d3b2ec _7b80b09a _pub-7b80b09a",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Content };

var ContentInner = React.memo(React.forwardRef(function ContentInner(props, ref) {
  return React.createElement("div", {
    "className": "_bcddd3c0 _7b80b09a _pub-7b80b09a",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { ContentInner };

var ConnectableServiceItem = React.memo(React.forwardRef(function ConnectableServiceItem(props, ref) {
  return React.createElement("button", {
    "className": "_55be76f5 _7b80b09a _pub-7b80b09a" + (props["active"] ? " " + "_7b80b09a_active _pub-7b80b09a_active active" : ""),
    "ref": ref,
    "data-testid": props["testId"],
    "onClick": props["onClick"],
  }, 
    React.createElement("div", {
      "className": "_79898ddf _7b80b09a _pub-7b80b09a",
    }, 
      props["icon"]
    ),
    React.createElement("div", {
      "className": "_90ea28ea _7b80b09a _pub-7b80b09a",
    }, 
      props["label"]
    )
  )
}));
export { ConnectableServiceItem };

var Icon = React.memo(React.forwardRef(function Icon(props, ref) {
  return React.createElement("div", {
    "className": "_bbb017d9 _7b80b09a _pub-7b80b09a",
    "ref": ref,
  }, 
    React.createElement("img", {
      "className": "_e304080b _7b80b09a _pub-7b80b09a",
      "src": props["src"],
    }, null)
  )
}));
export { Icon };

var Title = React.memo(React.forwardRef(function Title(props, ref) {
  return React.createElement("div", {
    "className": "_5c083ade _7b80b09a _pub-7b80b09a",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Title };

var Subtitle = React.memo(React.forwardRef(function Subtitle(props, ref) {
  return React.createElement("div", {
    "className": "_79fee12f _7b80b09a _pub-7b80b09a",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Subtitle };

var InfoLink = React.memo(React.forwardRef(function InfoLink(props, ref) {
  return React.createElement("a", {
    "className": "_97f08003 _7b80b09a _pub-7b80b09a",
    "ref": ref,
    "href": props["href"],
    "onClick": props["onClick"],
    "target": "_blank",
  }, 
    props["children"]
  )
}));
export { InfoLink };

var FilterContainer = React.memo(React.forwardRef(function FilterContainer(props, ref) {
  return React.createElement("div", {
    "className": "_7e932536 _7b80b09a _pub-7b80b09a",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { FilterContainer };

var ConnectServiceForm = React.memo(React.forwardRef(function ConnectServiceForm(props, ref) {
  return React.createElement("form", {
    "className": "_909d441a _7b80b09a _pub-7b80b09a",
    "ref": ref,
    "onSubmit": props["onSubmit"],
  }, 
    props["children"]
  )
}));
export { ConnectServiceForm };

var Services = React.memo(React.forwardRef(function Services(props, ref) {
  return React.createElement("ul", {
    "className": "_7725691d _7b80b09a _pub-7b80b09a",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Services };

var ConnectServiceFooter = React.memo(React.forwardRef(function ConnectServiceFooter(props, ref) {
  return React.createElement("div", {
    "className": "_60e5d06e _7b80b09a _pub-7b80b09a",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { ConnectServiceFooter };

var VerticalSpacer = React.memo(React.forwardRef(function VerticalSpacer(props, ref) {
  return React.createElement("div", {
    "className": "_8eebb142 _7b80b09a _pub-7b80b09a",
    "ref": ref,
  }, null)
}));
export { VerticalSpacer };

var ConnectBadge = React.memo(React.forwardRef(function ConnectBadge(props, ref) {
  return React.createElement("div", {
    "className": "_67881477 _7b80b09a _pub-7b80b09a",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_4a8914ee _7b80b09a _pub-7b80b09a",
    }, 
      React.createElement("i", {
        "className": "_9d0d382a _7b80b09a _pub-7b80b09a",
      }, null)
    ),
    React.createElement("div", {
      "className": "_d3804554 _7b80b09a _pub-7b80b09a",
    }, 
      props["icon"]
    )
  )
}));
export { ConnectBadge };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement("div", {
    "className": "_8986755b _7b80b09a _pub-7b80b09a" + (props["class"] ? " " + props["class"] : ""),
    "ref": ref,
  }, 
    React.createElement(_pubC530B6D5_ModalPreview, {
      "class": "_3e0af016 _7b80b09a _pub-7b80b09a",
      "visible": true,
      "side": true,
      "narrow": true,
      "padded": true,
    }, 
      props["showSelection"]&&React.createElement(React.Fragment, {
      }, 
        React.createElement(Header, {
          "class": "_a25d497f",
        }, 
          React.createElement(Title, {
            "class": "_e08d46ec",
          }, 
            "Add linked account"
            
          ),
          React.createElement(Subtitle, {
            "class": "_978a767a",
          }, 
            "Select your payment processor"
            
          ),
          React.createElement(FilterContainer, {
            "class": "_e8327c0",
          }, 
            React.createElement(_pub5Ab99334, {
              "class": "_29da141a _7b80b09a _pub-7b80b09a",
              "v3": true,
              "small": true,
              "round": true,
            }, null)
          )
        ),
        React.createElement(Content, {
          "class": "_d55a79e9",
        }, 
          React.createElement(Services, {
            "class": "_e14f2cdb",
          }, 
            React.createElement(ConnectableServiceItem, {
              "class": "_173ee9c4",
              "icon": React.createElement(Icon, {
                "class": "_68c5e761",
                "src": "../Services/affirm.svg",
              }, null),
              "label": "item 2",
            }, null),
            React.createElement(ConnectableServiceItem, {
              "class": "_6039d952",
              "icon": React.createElement(Icon, {
                "class": "_71ded620",
                "src": "../Services/paypal.svg?",
              }, null),
              "label": "item 1",
            }, null),
            React.createElement(ConnectableServiceItem, {
              "class": "_f93088e8",
              "icon": React.createElement(Icon, {
                "class": "_5af385e3",
                "src": "../Services/klarna.svg",
              }, null),
              "label": "item 2",
            }, null),
            React.createElement(ConnectableServiceItem, {
              "class": "_8e37b87e",
              "icon": React.createElement(Icon, {
                "class": "_43e8b4a2",
                "src": "../Services/quickbooks.svg",
              }, null),
              "label": "item 3",
            }, null),
            React.createElement(ConnectableServiceItem, {
              "class": "_10532ddd",
              "active": true,
              "icon": React.createElement(Icon, {
                "class": "_7bae12f3",
                "src": "../Services/xero.svg",
              }, null),
              "label": "item 4",
            }, null),
            React.createElement(ConnectableServiceItem, {
              "class": "_67541d4b",
              "icon": React.createElement(Icon, {
                "class": "_15b21324",
                "src": "../Services/workday.svg",
              }, null),
              "label": "item 5",
            }, null)
          )
        ),
        React.createElement(ConnectServiceFooter, {
          "class": "_4c532853",
        }, 
          React.createElement(_pub2Dbb4493, {
            "class": "_e3099282 _7b80b09a _pub-7b80b09a",
            "v3": true,
            "transparent": true,
          }, 
            "Cancel"
            
          )
        )
      ),
      props["showForm"]&&React.createElement(React.Fragment, {
      }, 
        React.createElement(Header, {
          "class": "_873616a3",
        }, 
          React.createElement(HeaderNav, {
            "class": "_36d4a5f1",
            "hover": true,
          }, null),
          React.createElement(ConnectBadge, {
            "class": "_41d39567",
            "icon": React.createElement("img", {
              "className": "_274cf84f _7b80b09a _pub-7b80b09a",
              "src": getDefault(require("../Services/stripe.svg")),
            }, null),
          }, null),
          React.createElement(Title, {
            "class": "_d8dac4dd",
            "bold": true,
          }, 
            "Connect your stripe account"
            
          ),
          React.createElement(Subtitle, {
            "class": "_afddf44b",
          }, 
            "\n          To authorize with Stripe, enter your credentials below.\n        "
            
          ),
          React.createElement(InfoLink, {
            "class": "_31b961e8",
          }, 
            "Where do I find this?"
            
          )
        ),
        React.createElement(Content, {
          "class": "_f0312635",
        }, 
          React.createElement(ConnectServiceForm, {
            "class": "_3716cfc6",
          }, 
            React.createElement(_pubDf2A042, {
              "class": "_d151e043 _7b80b09a _pub-7b80b09a",
              "v3": true,
              "label": "Token",
            }, 
              React.createElement(_pub5Ab99334, {
                "class": "_3c745753 _7b80b09a _pub-7b80b09a",
                "small": true,
                "round": true,
              }, null)
            ),
            React.createElement(_pubDf2A042, {
              "class": "_a656d0d5 _7b80b09a _pub-7b80b09a",
              "v3": true,
              "active": true,
              "label": "Client Secret",
            }, 
              React.createElement(_pub5Ab99334, {
                "class": "_3db63d64 _7b80b09a _pub-7b80b09a",
                "v3": true,
                "active": true,
                "small": true,
                "round": true,
              }, null)
            )
          )
        ),
        React.createElement(ConnectServiceFooter, {
          "class": "_6938778f",
        }, 
          React.createElement(_pub2Dbb4493, {
            "class": "_3550719f _7b80b09a _pub-7b80b09a",
            "v3": true,
            "transparent": true,
          }, 
            "Cancel"
            
          ),
          React.createElement(_pub2Dbb4493, {
            "class": "_42574109 _7b80b09a _pub-7b80b09a",
            "v3": true,
            "primary": true,
          }, 
            "Connect"
            
          )
        )
      ),
      props["connecting"]&&React.createElement(React.Fragment, {
      }, 
        React.createElement(Header, {
          "class": "_2d3fde28",
        }, 
          React.createElement(HeaderNav, {
            "class": "_7b1c04fa",
            "noBack": true,
          }, null),
          React.createElement(ConnectBadge, {
            "class": "_c1b346c",
            "icon": React.createElement("img", {
              "className": "_b0d3e966 _7b80b09a _pub-7b80b09a",
              "src": getDefault(require("../Services/paypal.svg")),
            }, null),
          }, null),
          React.createElement(Title, {
            "class": "_951265d6",
          }, 
            "Connecting in a new tab..."
            
          )
        ),
        React.createElement(VerticalSpacer, {
          "class": "_5a38eebe",
        }, null),
        React.createElement(ConnectServiceFooter, {
          "class": "_c331bf04",
        }, 
          React.createElement(_pub2Dbb4493, {
            "class": "_7898d094 _7b80b09a _pub-7b80b09a",
            "v3": true,
            "transparent": true,
          }, 
            "Cancel"
            
          )
        )
      )
    )
  )
}));
export { Preview };


