import { AvailableServiceInfo } from './base';
import { env } from '@hum/common';
import { openOAUthWindow2 } from '@hum/icm-app/src/utils';
import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { stringify } from 'query-string';
import { getUrlForConnectorsRedirection } from './connectorsUtils';

const baseUrl =
  env.NEXT_STATIC_PAYPAL_API_URL || 'https://www.sandbox.paypal.com/connect';

const getConfig = (clientId: string) => ({
  flowEntry: 'static',
  client_id: clientId,
  scope: 'openid https://uri.paypal.com/services/paypalattributes',
  redirect_uri: `${getUrlForConnectorsRedirection()}/connect/paypal/`,
});

const sandboxClientId =
  'Aey8i1Xfa2GSGzPuoNhVFG2kxB_zONXaFh-NHHuG_Yu1pezP-x_c5sMqFNRD13qa25yoNgldLVUqVde0';

const config = {
  flowEntry: 'static',
  client_id: env.NEXT_STATIC_PAYPAL_CLIENT_ID || sandboxClientId,
  scope: 'openid https://uri.paypal.com/services/paypalattributes',
  redirect_uri: `${getUrlForConnectorsRedirection()}/connect/paypal/`,
};

const OAUTH_URL = `${baseUrl}?${stringify(config)}`;

const getOAuthUrl = (clientId: string) =>
  `${baseUrl}?${stringify(getConfig(clientId))}`;

export const paypalOAuthConnector: AvailableServiceInfo = {
  label: 'Paypal',
  icon: require('./paypal.svg'),
  type: ConnectorType.Paypal,
  source: ConnectorSource.Capital,
  async connect() {
    return await openOAUthWindow2(OAUTH_URL);
  },
};

export const paypalDirectConnector: AvailableServiceInfo = {
  label: 'Paypal',
  icon: require('./paypal.svg'),
  type: ConnectorType.Paypal,
  source: ConnectorSource.Capital,
  helpFindLink:
    'https://drive.google.com/file/d/1KAocumN-ECNUtD-jXbSffCPwW2SnVGEa/view?usp=sharing',
  fields: [
    {
      label: 'Client ID',
      propertyName: 'client_id',
    },
    {
      label: 'Client Secret',
      propertyName: 'client_secret',
    },
  ],
  async connect({ client_id, client_secret }) {
    const { code } = await openOAUthWindow2(getOAuthUrl(client_id));

    return { code, client_id, client_secret };
  },
};
