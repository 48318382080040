import { createDataResult, createErrorResult } from '@hum/common';
import { client as backend } from '@hum/common/src/api/client';
import { normalize } from '@hum/icm-app/src/backend/api/models';

// Demo response while we get HL 2.5 ready
export const DEMO_RESPONSE = {
  codat_company_id: 123,
  codat_connection_id: 1234,
  codat_connect_url:
    'https://link.codat.io/company/1126743b-113d-4d72-b14f-36d6742df487',
};

export enum CodatConnectorTypeValue {
  QuickBooks = 'quickbooks',
  QuickBooksSandbox = 'quickbooks_sandbox',
  Netsuite = 'netsuite',
}

type CodatConnectionUrlResponse = {
  connectUrl: string;
};

export const getCodatConnectionUrl = async ({
  companyId,
  type,
}: {
  companyId: number;
  type: CodatConnectorTypeValue;
}) => {
  const codatUrl = await backend
    .post(
      `companies/${companyId}/create_codat_connector`,
      normalize.codatConnectUrl.out({ type })
    )
    .then((response) =>
      createDataResult(
        normalize.codatConnectUrl.in(
          response.meta
        ) as CodatConnectionUrlResponse
      )
    )
    .catch(createErrorResult);

  return codatUrl;
};
