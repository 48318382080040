import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const affirmDirectConnector: AvailableServiceInfo = {
  label: 'Affirm',
  icon: require('./affirm.svg'),
  isBeta: true,
  type: ConnectorType.Affirm,
  source: ConnectorSource.Capital,
  fields: [
    {
      label: 'Enter your API Key',
      propertyName: 'api_key',
    },
    {
      label: 'Enter your API Secret',
      propertyName: 'api_secret',
    },
  ],
  connect: (params) => params,
};
