// TODO - need to refactor in sagas
import React from 'react';
import * as styles from './index.pc';
import { TextInputField } from '@hum/legacy-ui';
import { Button, ButtonType, Scrollable } from '@hum/ui-library';
import { AvailableServiceInfo } from '../Services/base';
import * as Yup from 'yup';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';

// Generate an object like { field1: undefined, field2: undefined }
// that will be used as the initial value of the form
export const generateInitialValues = (service: AvailableServiceInfo | null) => {
  const { fields = [] } = service || { fields: [] };
  return fields.reduce(
    (allFields, field) => ({ ...allFields, [field.propertyName]: undefined }),
    {}
  );
};

// Generate a Yup validation schema that sets all the fields
// that a connector requires as required
export const generateValidationSchema = (
  service: AvailableServiceInfo | null
) => {
  const { fields = [] } = service || { fields: [] };
  return Yup.object().shape(
    fields.reduce(
      (allFields, field) => ({
        ...allFields,
        [field.propertyName]: Yup.string().required('Required'),
      }),
      {}
    )
  );
};

const InfoHelpLink = ({ service }: { service: AvailableServiceInfo }) => {
  const flags = useFlags();

  // TODO: research this further – Julio
  if (service.helpFindLink) {
    if (
      !service.helpLinkFeatureFlag ||
      flags.enabled(service.helpLinkFeatureFlag)
    ) {
      return (
        <styles.InfoLink href={service.helpFindLink}>
          Where do I find this?
        </styles.InfoLink>
      );
    }
  }
  return null;
};

export const ServiceFormFields = ({
  service,
  form,
}: {
  service: AvailableServiceInfo;
  form: any; // TODO: we don't have real types defined for our formik forms, add it to useForm
}) => {
  return (
    <>
      <InfoHelpLink service={service} />

      {service.fields?.map((field, i) => {
        const onValueChange = (value: string) =>
          form.setFieldValue(field.propertyName, value, true);

        const [input, meta] = form.getFieldProps({
          name: field.propertyName,
          type: 'text',
        });

        return (
          <TextInputField
            data-testid={`${field.propertyName}-input`}
            autoFocus={i === 0}
            {...input}
            error={meta.touched ? meta.error : undefined}
            label={field.label}
            key={`${field.propertyName}-${i}`}
            onValueChange={onValueChange}
            v3
            left
          />
        );
      })}
    </>
  );
};

export const SelectedServiceTab = ({
  onBackButtonClick,
  onCancelClick,
  service,
  hideServiceOptions,
  directConnect,
}: {
  onBackButtonClick: () => void;
  onCancelClick: () => void;
  hideServiceOptions?: boolean;
  service: AvailableServiceInfo;
  directConnect: { newDirectConnectForm };
}) => {
  return (
    <>
      <styles.Header>
        <styles.HeaderNav
          noBack={hideServiceOptions}
          backButtonTestId="back-button"
          onBackButtonClick={onBackButtonClick}
        />
        <styles.ConnectBadge icon={<img src={service.icon} />} />
        <styles.Title>Connect your {service.label} account</styles.Title>
        <styles.Subtitle>
          To authorize with {service.label}, enter your credentials below
        </styles.Subtitle>
      </styles.Header>
      <styles.Content>
        <Scrollable>
          <styles.ConnectServiceForm
            onSubmit={directConnect.newDirectConnectForm.handleSubmit}
          >
            <ServiceFormFields
              service={service}
              form={directConnect.newDirectConnectForm}
            />
          </styles.ConnectServiceForm>
        </Scrollable>
      </styles.Content>

      <styles.ConnectServiceFooter>
        <Button
          testId="cancel-button"
          type={ButtonType.QUIET}
          onClick={onCancelClick}
        >
          Cancel
        </Button>
        <Button
          testId="connect-button"
          onClick={() => {
            directConnect.newDirectConnectForm.handleSubmit();
          }}
        >
          Connect
        </Button>
      </styles.ConnectServiceFooter>
    </>
  );
};
