import React, { useEffect } from 'react';
import identity from 'lodash/identity';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CompanyType } from '@hum/types';
import { domHistory } from '@hum/common';
import { OnboardingConnectStep } from './onboarding-v5/components/OnboardingConnectStep';
import Hotjar from '@hotjar/browser';
import { initDataDog, setDataDogUser } from '../utils/monitoring';

if (process.env.NODE_ENV === 'production') {
  Hotjar.init(3569412, 6);
  initDataDog();
}

import './_DEPRECATED_styles/index.scss';
import './styles.pc';

export const TALL_MEDIA_QUERY = `screen and (max-width: 830px)`;

import { AppState, Routes } from '@hum/icm-app/src/state';

import { Admin } from './admin';
import { AuthPage } from './auth';
import { CompanyActivation } from './company-activation';
import { CompanyInsights } from './insights/CompanyInsights';
import { CompanyPublicProfile } from './syndication-v2/companyPublicDetail';
import { ConnectPage } from './connect';
import { LabelingUIPage } from './label-mapper';
import { InvestorPortfolio } from './portfolio';
import { InvitePage } from './invite';
import { Gateway } from './gateway';
import { OnboardingV5 } from './onboarding-v5';
import { SettingsPage } from './settings';
import { VerifyInformation } from '@hum/icm-app/src/pages/syndication-v2/VerifyInformation/VerifyInformation';
import { SyndicationInvestorV2Page } from './syndication-v2';
import { apiUserReviewSessionRequestSent } from '@hum/icm-app/src/actions';
import { withCobranding } from '../hocs/withCobranding';
import { PortfolioMonitoringPage } from './portfolio-monitoring';
import { ArchiveCompanyDetailPage } from './settings/archive/CompanyDetail';
import RaiseOrInvest from '@hum/icm-app/src/pages/onboarding-v5/components/RaiseOrInvest';
import { AdminAnalyticsWithCharts } from '@hum/icm-app/src/pages/admin/AdminAnalytics/AdminAnalyticsWithCharts';
import { Provider } from '../Provider';
import {
  __do_not_use_me_api,
  __do_not_use_me_unless_you_know_what_you_are_doing__store,
} from '../store';

import Dashboard from '@hum/icm-app/src/pages/dashboard';
import { UnsupportedCountry } from '@hum/icm-app/src/pages/auth/choose-your-adventure/AboutYourBusiness/UnsupportedCountry';
import { useApplyTheme, Spinner, MaintenanceBanner } from '@hum/ui-library';
import {
  CompanyInvestorsDetailsPage,
  CompanyInvestorsPage,
} from './SmartMatchPage/LegacyMatchesPage';

import { SmartRaisePage } from './SmartRaisePage';
import { MafiConnectPage } from '@hum/icm-app/src/components/MafiConnect';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';

const investorOrAdmin = [
  CompanyType.BalanceSheetPartner,
  CompanyType.Investor,
  CompanyType.SyndicationInvestor,
  CompanyType.Admin,
];

const companyOrAdmin = [CompanyType.Company, CompanyType.Admin];

const anyRole = [
  CompanyType.BalanceSheetPartner,
  CompanyType.Investor,
  CompanyType.SyndicationInvestor,
  CompanyType.Company,
  CompanyType.Admin,
];

type RouteInfo = {
  path: string;
  component: React.ReactNode;
  roles?: CompanyType[];
  featureFlag?: string;
};

const routesToComponents: RouteInfo[] = [
  {
    path: Routes.ADMIN_COMPANY_ANALYTICS,
    component: <AdminAnalyticsWithCharts />,
    roles: [CompanyType.Admin],
  },
  {
    path: Routes.SETTINGS_SYNDICATION_ARCHIVE_DETAIL,
    component: <ArchiveCompanyDetailPage />,
    roles: anyRole,
  },
  {
    path: Routes.LABEL_MAPPER,
    component: <LabelingUIPage />,
    roles: [CompanyType.Admin],
  },
  {
    path: Routes.BRANDING,
    component: <SettingsPage />,
    roles: investorOrAdmin,
  },
  {
    path: Routes.SUBDOMAIN,
    component: <SettingsPage />,
    roles: investorOrAdmin,
  },
  {
    path: Routes.SETTINGS_ACCOUNT,
    component: <SettingsPage />,
    roles: anyRole,
  },
  {
    path: Routes.SETTINGS_FUNDING_PROFILE,
    component: <SettingsPage />,
    roles: anyRole,
  },
  {
    path: Routes.SETTINGS_SYNDICATION_ARCHIVE,
    component: <SettingsPage />,
    roles: anyRole,
  },
  {
    path: Routes.SETTINGS_DOCUMENTS,
    component: <SettingsPage />,
    roles: anyRole,
  },
  {
    path: Routes.MANAGE_TEAM,
    component: <SettingsPage />,
    roles: anyRole,
  },
  {
    path: Routes.ADMIN,
    component: <Admin />,
    roles: [CompanyType.Admin],
  },
  {
    path: Routes.INVITE_COMPANIES,
    component: <InvestorPortfolio />,
    roles: investorOrAdmin,
  },
  {
    path: Routes.PORTFOLIO_COMPANIES,
    component: <InvestorPortfolio />,
    roles: investorOrAdmin,
  },
  {
    path: Routes.GATEWAY,
    component: <Gateway />,
    roles: anyRole,
  },
  {
    path: Routes.ONBOARDING_V5,
    component: <OnboardingV5 />,
    roles: [CompanyType.Company, CompanyType.Admin],
  },
  {
    path: Routes.ONBOARDING_V5_CONNECT,
    component: <OnboardingV5 />,
    roles: [CompanyType.Company, CompanyType.Admin],
  },
  {
    path: Routes.ONBOARDING_INDEX,
    component: <Redirect to={Routes.ONBOARDING_V4 + window.location.search} />,
    roles: anyRole,
  },
  {
    path: Routes.COMPANY_ACTIVATION_CONNECTORS_CONNECT,
    component: () => <OnboardingConnectStep />,
    roles: [CompanyType.Company, CompanyType.Admin],
  },
  {
    path: Routes.COMPANY_ACTIVATION_GET_STARTED,
    component: <Dashboard />,
    roles: companyOrAdmin,
  },
  {
    path: Routes.COMPANY_ACTIVATION_ROOT,
    component: <CompanyActivation />,
    roles: [CompanyType.Company, CompanyType.Admin],
  },
  {
    path: Routes.INSIGHTS_CHART_TAB,
    component: <CompanyInsights />,
    roles: anyRole,
  },
  { path: Routes.INSIGHTS, component: <CompanyInsights />, roles: anyRole },
  { path: Routes.CONNECT, component: <ConnectPage />, roles: anyRole },
  {
    path: Routes.VERIFY_INFO,
    component: <VerifyInformation />,
    roles: [CompanyType.SyndicationInvestor],
  },
  {
    path: Routes.SYNDICATION,
    component: <SyndicationInvestorV2Page />,
    roles: [CompanyType.SyndicationInvestor],
  },
  {
    path: Routes.PORTFOLIO_MONITORING,
    component: <PortfolioMonitoringPage />,
    roles: [CompanyType.Investor],
  },
  { path: Routes.COMPANY_PUBLIC_PROFILE, component: <CompanyPublicProfile /> },
  { path: Routes.INVITE, component: <InvitePage /> },
  { path: Routes.LOGIN, component: <AuthPage /> },
  { path: Routes.RAISE_OR_INVEST, component: <RaiseOrInvest /> },
  { path: Routes.RECOVER, component: <AuthPage /> },
  { path: Routes.SET_PASSWORD, component: <AuthPage /> },
  { path: Routes.SIGNUP, component: <AuthPage /> },
  { path: Routes.SIGNUP_V4_COMPLETED, component: <AuthPage /> },
  { path: Routes.VERIFY_EMAIL, component: <AuthPage /> },
  { path: Routes.UNSUPPORTED_COUNTRY, component: <UnsupportedCountry /> },
  {
    path: Routes.INTRODUCTIONS_INVESTOR,
    component: <CompanyInvestorsDetailsPage />,
    roles: [CompanyType.Company, CompanyType.Admin],
  },
  {
    path: Routes.INTRODUCTIONS,
    component: <CompanyInvestorsPage />,
    roles: [CompanyType.Company, CompanyType.Admin],
  },
  {
    path: Routes.SMARTRAISE,
    component: <SmartRaisePage />,
    roles: [CompanyType.Company, CompanyType.Admin],
  },
  {
    path: Routes.SETTINGS,
    component: (
      <Redirect to={Routes.SETTINGS_ACCOUNT + window.location.search} />
    ),
  },
  {
    path: Routes.QUALIFY,
    component: <Redirect to={Routes.SIGNUP + window.location.search} />,
  },
  {
    path: Routes.MAFI_CONNECT,
    component: <MafiConnectPage />,
    roles: [CompanyType.Admin],
    // featureFlag: 'enable-mafi-connect',
  },

  {
    path: Routes.ROOT,
    component: () => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const returnTo = params.get('returnTo');

      // if the link has a returnTo param, it means the user already has an account, do not take them to signup
      const redirectTo = returnTo ? Routes.LOGIN : Routes.RAISE_OR_INVEST;

      return <Redirect to={redirectTo + window.location.search} />;
    },
  },
  {
    path: Routes.ROOT,
    component: <Redirect to={Routes.LOGIN + window.location.search} />,
  },
];

const BaseMain = withCobranding(() => {
  const dispatch = useDispatch();
  const { session } = (useSelector(identity) as any) as AppState;
  const flags = useFlags();

  useEffect(() => {
    if (session.data && process.env.NODE_ENV === 'production') {
      Hotjar.identify(`${session.data.id}`, {
        first_name: session.data.firstName,
        isAdmin: session.data.roles.includes(CompanyType.Admin),
        isInvestor: session.data.roles.includes(CompanyType.Investor),
        isCompany: session.data.roles.includes(CompanyType.Company),
        isSyndicationInvestor: session.data.roles.includes(
          CompanyType.SyndicationInvestor
        ),
        email: session.data.email,
      });

      setDataDogUser(session.data);
    }
  }, [session]);

  // const history = useHistory();
  const { pathname, search } = window.location;

  useEffect(() => {
    // FIXME: this needs to go in a saga
    if (!session.loaded) {
      dispatch(apiUserReviewSessionRequestSent({}));
    }
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');

    // keep this logic around since we'll be doing dynamic theming between light & dark
    body?.classList.add('hum', 'dark');
  }, []);

  if (!session.loaded) {
    return <Spinner fullScreen />;
  }
  const banner = flags.enabled('maintenance-banner')
    ? (flags.value('maintenance-banner') as Record<string, any>)
    : null;

  return (
    <>
      {flags.enabled('maintenance-banner') && (
        <MaintenanceBanner title={banner?.title} message={banner?.message} />
      )}
      <Switch>
        {routesToComponents.map((route) => {
          if (route.featureFlag && !flags.enabled(route.featureFlag)) {
            return null; // Skip rendering if the feature flag is not enabled
          }
          // do not show any content if your user exists but does not have permission to see it
          if (route.roles?.length) {
            // if session loaded and there's not data, redirect to login but only if path matches current location
            if (!session.data?.id && pathname.startsWith(route.path)) {
              // if there was a location/query, keep it when logging in
              const returnToPathname = pathname;
              const returnToQuery = search.substring(1);
              return (
                <Redirect
                  to={{
                    pathname: Routes.LOGIN,
                    search: search
                      ? `?returnTo=${returnToPathname}&${returnToQuery}`
                      : '',
                  }}
                />
              );
            }
            // if session loaded and there's data, return null if user doesn't have acccess
            if (
              !session.data?.roles.some((sessionRoles) =>
                route.roles?.includes(sessionRoles)
              )
            ) {
              return null;
            }
          }

          return (
            <Route path={route.path} key={route.path}>
              {route.component}
            </Route>
          );
        })}
      </Switch>
    </>
  );
});

const queryClient = new QueryClient();

export const Main = () => {
  useApplyTheme();

  return (
    <Provider
      store={__do_not_use_me_unless_you_know_what_you_are_doing__store}
      api={__do_not_use_me_api}
      history={domHistory}
    >
      <QueryClientProvider client={queryClient}>
        <BaseMain />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
};
