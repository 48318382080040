import React, { ReactElement } from 'react';
import { Text, TextType, IconProps } from '@hum/ui-library';

export function Step({
  testId,
  title,
  description,
  header,
  icon,
  onClick,
}: {
  testId?: string;
  title: string;
  description?: string;
  header?: boolean;
  icon?: ReactElement<IconProps>;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) {
  const hoverBg = header ? '' : 'hover:bg-surface-subdued';
  return (
    <div
      className={`p-8 border border-borderColor ${hoverBg}`}
      data-testid={testId}
      onClick={onClick}
    >
      <div className="flex justify-between items-center">
        <div className="block md:max-w-2xl">
          {header ? (
            <Text type={TextType.DISPLAY_S}>{title}</Text>
          ) : (
            <Text type={TextType.HEADING_M}>{title}</Text>
          )}
          <Text type={TextType.BODY_S} subdued>
            {description}
          </Text>
        </div>
        {icon && React.cloneElement(icon, { className: 'cursor-pointer' })}
      </div>
    </div>
  );
}
