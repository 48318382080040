import React from 'react';
import styled from 'styled-components';

// icons
import ShieldGrey from '@hum/assets/icons/shield-grey.svg';
import BookOpen from '@hum/assets/icons/book-open.svg';
import ShoppingMoney from '@hum/assets/icons/shopping-money.svg';

const StyledTitle = styled.h3`
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  font-family: var(--font-family-body);
  cursor: default;
  user-select: none;
  margin-top: var(--spacing-10);
  margin-bottom: var(--spacing-7);
`;

const StyledInfoCard = styled.div`
  display: flex;
  flex-flow: column;
  padding: 24px;
  background-color: var(--surface-dark-default);
  border-radius: 4px;
  user-select: none;

  img {
    display: block;
    align-self: start;
    height: 24px;
    margin: 0;
    opacity: 1;
  }

  h3 {
    margin: var(--spacing-5) 0 var(--spacing-3);
    font-size: 14px;
    color: var(--text-dark-primary);
    line-height: 1;
  }

  p {
    font-size: 14px;
    color: var(--text-dark-subdued);
    line-height: 1.5;
  }
`;

export const StyledInfoCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: var(--spacing-7);

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const InfoCard = ({
  title,
  content,
  icon,
}: {
  title: React.ReactNode;
  content: React.ReactNode;
  icon: React.ReactNode;
}) => (
  <StyledInfoCard>
    {icon}
    <h3>{title}</h3>
    <p>{content}</p>
  </StyledInfoCard>
);

export const LearningResources = () => {
  return (
    <>
      <StyledTitle>Learning Resources</StyledTitle>
      <StyledInfoCardWrapper>
        <InfoCard
          icon={<img src={BookOpen} />}
          title="Why connect?"
          content={
            <span>
              Learn about the benefits you get from connecting.{' '}
              <a
                className="ui-link"
                href="https://info.humcapital.com/hum-help-center/data-connectors"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
            </span>
          }
        />
        <InfoCard
          icon={<img src={ShieldGrey} />}
          title="Data Security"
          content={
            <span>
              Learn how Hum secures your data.{' '}
              <a
                className="ui-link"
                href="https://info.humcapital.com/hum-help-center/security-and-data-privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
            </span>
          }
        />
        <InfoCard
          icon={<img src={ShoppingMoney} />}
          title="Fundraising process"
          content={
            <span>
              FAQs about fundraising with Hum.{' '}
              <a
                className="ui-link"
                href="https://info.humcapital.com/hum-help-center/the-fundraising-process"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
            </span>
          }
        />
      </StyledInfoCardWrapper>
    </>
  );
};
