import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const holdedDirectConnector: AvailableServiceInfo = {
  label: 'Holded',
  icon: require('./holded.svg'),
  type: ConnectorType.Holded,
  source: ConnectorSource.Capital,
  isBeta: true,
  fields: [
    {
      label: 'Enter your API key',
      propertyName: 'api_key',
    },
  ],
  connect: (params) => params,
};
