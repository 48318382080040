// Components
import ConnectOauth from '../common';
import { ConnectorType } from '@hum/icm-app/src/state';

const getOAuthCodeParams = (code: string): Record<string, string> => {
  return {
    code,
  };
};

export const ConnectSquare = ConnectOauth(
  ConnectorType.Square,
  getOAuthCodeParams
);
