import React from "react";
import _pubA97D53F3 from "../../../../design-system/src/atoms.pc";
import _pub2F51C399 from "../../../../design-system/src/breakpoints.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../design-system/src/utils.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var SharingWrapper = React.memo(React.forwardRef(function SharingWrapper(props, ref) {
  return React.createElement("div", {
    "className": "_a8efce0c _719ea7a6 _pub-719ea7a6" + (props["top"] ? " " + "_719ea7a6_top _pub-719ea7a6_top top" : "") + (props["chooseYourAdventureVariant"] ? " " + "_719ea7a6_choose-your-adventure-variant _pub-719ea7a6_choose-your-adventure-variant choose-your-adventure-variant" : ""),
    "ref": ref,
    "data-testid": props["dataTestId"],
  }, 
    props["children"]
  )
}));
export { SharingWrapper };

var PublicProfileWrapper = React.memo(React.forwardRef(function PublicProfileWrapper(props, ref) {
  return React.createElement("div", {
    "className": "_46e1af20 _719ea7a6 _pub-719ea7a6",
    "ref": ref,
    "data-testid": props["dataTestId"],
  }, 
    props["children"]
  )
}));
export { PublicProfileWrapper };

var MarginTop40 = React.memo(React.forwardRef(function MarginTop40(props, ref) {
  return React.createElement("div", {
    "className": "_a1598227 _719ea7a6 _pub-719ea7a6",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { MarginTop40 };

var PaddingTop40 = React.memo(React.forwardRef(function PaddingTop40(props, ref) {
  return React.createElement("div", {
    "className": "_80b28881 _719ea7a6 _pub-719ea7a6",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { PaddingTop40 };

var BlankSlate = React.memo(React.forwardRef(function BlankSlate(props, ref) {
  return React.createElement("div", {
    "className": "_6ebce9ad _719ea7a6 _pub-719ea7a6" + " " + "_719ea7a6_v3 _pub-719ea7a6_v3 v3" + (props["hasItems"] ? " " + "_719ea7a6_hasItems _pub-719ea7a6_hasItems hasItems" : ""),
    "ref": ref,
    "data-testid": props["dataTestId"],
  }, 
    React.createElement("div", {
      "className": "_f252beee _719ea7a6 _pub-719ea7a6",
    }, 
      props["img"]&&!props["hasItems"]&&(React.createElement("img", {
        "className": "_730f7509 _719ea7a6 _pub-719ea7a6" + " " + "_719ea7a6_illustration _pub-719ea7a6_illustration illustration",
        "src": props["img"],
        "alt": "",
      }, null)),
      React.createElement("h1", {
        "className": "_4500ab2b _719ea7a6 _pub-719ea7a6",
      }, 
        props["title"]
      ),
      React.createElement("div", {
        "className": "_32079bbd _719ea7a6 _pub-719ea7a6",
      }, 
        props["description"]
      ),
      props["children"]&&React.createElement("div", {
        "className": "_e78814c _719ea7a6 _pub-719ea7a6",
      }, 
        props["children"]
      )
    )
  )
}));
export { BlankSlate };

var Title = React.memo(React.forwardRef(function Title(props, ref) {
  return React.createElement("h1", {
    "className": "_87df4c98 _719ea7a6 _pub-719ea7a6",
    "ref": ref,
  }, 
    props["title"],
    props["description"]&&React.createElement("div", {
      "className": "_bddd4eff _719ea7a6 _pub-719ea7a6",
    }, 
      props["description"]
    )
  )
}));
export { Title };

var Subtitle = React.memo(React.forwardRef(function Subtitle(props, ref) {
  return React.createElement("h2", {
    "className": "_69d12db4 _719ea7a6 _pub-719ea7a6",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Subtitle };


