import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const yodleeDirectConnector: AvailableServiceInfo = {
  label: 'Yodlee',
  icon: require('./yodlee.svg'),
  type: ConnectorType.Yodlee,
  source: ConnectorSource.Capital,
  isBeta: true,
  fields: [
    {
      label: 'Enter your Login Name',
      propertyName: 'login_name',
    },
    {
      label: 'Enter your Client ID',
      propertyName: 'client_id',
    },
    {
      label: 'Enter your Secret',
      propertyName: 'secret',
    },
  ],
  async connect(params) {
    return params;
  },
};
