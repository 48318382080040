import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Help, MetaTitle, Box } from '@hum/legacy-ui';
import { CompanyFileDocumentType, CompanyType } from '@hum/types';
import { pageviewDocumentDataroom } from '@hum/icm-app/src/actions';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { UploadProgressPopup } from '@hum/icm-app/src/components/UploadProgressPopup';
import { CompanyDocuments } from '@hum/icm-app/src/components/CompanyDocuments';
import { useFileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import GreyShieldIcon from '@hum/assets/icons/shield-grey.svg';
import {
  Button,
  ButtonType,
  Icon,
  ProgressDownload,
  Text,
  TextType,
} from '@hum/ui-library';
import { useBulkDownload } from '@hum/icm-app/src/hooks/useBulkDownload';
import { useSessionUser } from '@hum/api';

type DocumentSection = {
  type: CompanyFileDocumentType;
  label: string;
  description: string;
  open: boolean;
  required: boolean;
  checklistTitle: string;
  checklistItems: string[];
  csvAndExcelOnly: boolean;
};

const documents: DocumentSection[] = [
  {
    type: CompanyFileDocumentType.BusinessOverviewDeck,
    label: 'Business Overview Deck',
    description: '',
    open: false,
    required: true,
    checklistTitle: '',
    checklistItems: [],
    csvAndExcelOnly: false,
  },
  {
    type: CompanyFileDocumentType.IncomeStatement,
    label: 'Profit & Loss Statement',
    description:
      'A breakdown of major revenue and expense accounts broken out by month, as far back as possible.',
    open: false,
    required: true,
    checklistTitle: 'How to prepare your file',
    checklistItems: [
      'Store all data in 1 file or make sure each file contains the same number of accounts',
      'Segment data by month',
      'Minimum 12 months of data',
    ],
    csvAndExcelOnly: true,
  },
  {
    type: CompanyFileDocumentType.BalanceSheet,
    label: 'Balance Sheet',
    description:
      'A breakdown of asset and liabilities accounts by month, as far back as possible.',
    open: false,
    required: true,
    checklistTitle: 'How to prepare your file',
    checklistItems: [
      'Store all data in 1 file or make sure each file contains the same number of accounts',
      'Segment data by month',
      'Minimum 12 months of data',
    ],
    csvAndExcelOnly: true,
  },
  {
    type: CompanyFileDocumentType.CashFlowStatement,
    label: 'Cash Flow',
    description: '',
    open: false,
    required: true,
    checklistTitle: 'How to prepare your file',
    checklistItems: [
      'Store all data in 1 file or make sure each file contains the same number of accounts',
      'Segment data by month',
      'Minimum 12 months of data',
    ],
    csvAndExcelOnly: true,
  },
  {
    type: CompanyFileDocumentType.CapTable,
    label: 'Cap Table',
    description: '',
    open: false,
    required: false,
    checklistTitle: '',
    checklistItems: [],
    csvAndExcelOnly: false,
  },
  {
    type: CompanyFileDocumentType.CustomerTape,
    label: 'Customer transaction data',
    description: '',
    open: false,
    required: false,
    checklistTitle: '',
    checklistItems: [],
    csvAndExcelOnly: false,
  },
  {
    type: CompanyFileDocumentType.Other,
    label: 'Additional Documents',
    description: '',
    open: false,
    required: false,
    checklistTitle: '',
    checklistItems: [],
    csvAndExcelOnly: false,
  },
];

export const DataRoom = () => {
  const flags = useFlags();
  const company = useCurrentCompany();
  const dispatch = useDispatch();
  const session = useSessionUser();

  const fileManager = useFileManager({ companyId: company?.data?.id || 0 });
  const {
    handleDownloadAllFilesClick,
    text,
    taskStatus,
    polling,
    cancelPolling,
  } = useBulkDownload({
    companyId: company.data?.id || 0,
  });

  useEffect(() => {
    dispatch(pageviewDocumentDataroom());
  }, []);

  if (!company.data) {
    return null;
  }

  return (
    <div data-testid="company-activation:dataroom">
      <MetaTitle>Documents</MetaTitle>
      <div className="flex items-center justify-between pt-6 pb-10">
        <Text type={TextType.DISPLAY_S}>Data room</Text>
        {flags.enabled('show-dataroom-download-all-button') &&
          (company.data.type.includes(CompanyType.Admin) ||
            session.data?.isAdmin) && (
            <Button
              type={ButtonType.PRIMARY}
              onClick={handleDownloadAllFilesClick}
              disabled={polling}
            >
              <Icon.Download className="mr-2" width="16" height="16" light />
              Download all
            </Button>
          )}
      </div>
      <CompanyDocuments
        sections={documents}
        fileManager={fileManager}
        company={company}
      />

      <UploadProgressPopup hideUntilComplete fileManager={fileManager} />

      <ProgressDownload
        text={text}
        polling={polling}
        status={taskStatus?.state}
        onCancel={cancelPolling}
      />

      <Box alignCenter vertical>
        <Help
          icon={<img src={GreyShieldIcon} />}
          content={<p>Transfer of your data is fully encrypted</p>}
        />
      </Box>
    </div>
  );
};
