import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';
import { env } from '@hum/common';
import { stringify } from 'query-string';
import { getUrlForConnectorsRedirection } from './connectorsUtils';

const sandboxClientId = '35c7dfed-7f48-469b-9023-bb1738d11d3d';

const BASE_FINCH_CONNECT_URI = 'https://connect.tryfinch.com';
const FINCH_CONNECT_IFRAME_ID = 'finch-connect-iframe';
const FINCH_AUTH_MESSAGE_NAME = 'finch-auth-message';

// https://connect.tryfinch.com/authorize?&client_id=35c7dfed-7f48-469b-9023-bb1738d11d3d&products=company directory&redirect_uri=https://tryfinch.com
const config = {
  client_id: env.FINCH_CLIENT_ID || sandboxClientId,
  products: ['payment company'],
  mode: 'employer',
  app_type: 'spa',
  redirect_uri:
    typeof window !== 'undefined' &&
    `${getUrlForConnectorsRedirection()}/connect/finch`,
};

const OAUTH_URL = `${BASE_FINCH_CONNECT_URI}/authorize?${stringify(config)}`;

export const finchConnector: AvailableServiceInfo = {
  label: 'Finch',
  icon: require('./finch.png'),
  type: ConnectorType.Finch,
  source: ConnectorSource.Capital,
  // taken from https://github.com/Finch-API/react-finch-connect/blob/master/src/index.js
  async connect() {
    const open = () => {
      if (document.getElementById(FINCH_CONNECT_IFRAME_ID)) {
        return null;
      }
      const iframe = document.createElement('iframe');
      iframe.src = OAUTH_URL;
      iframe.frameBorder = '0';
      iframe.id = FINCH_CONNECT_IFRAME_ID;
      iframe.style.position = 'fixed';
      iframe.style.zIndex = '999';
      iframe.style.height = '100%';
      iframe.style.width = '100%';
      iframe.style.top = '0';
      iframe.style.backgroundColor = 'none transparent';
      iframe.style.border = 'none';
      document.body.prepend(iframe);
      document.body.style.overflow = 'hidden';
    };

    const close = () => {
      const frameToRemove = document.getElementById(
        FINCH_CONNECT_IFRAME_ID
      ) as any;
      if (frameToRemove) {
        frameToRemove.parentNode.removeChild(frameToRemove);
        document.body.style.overflow = 'inherit';
      }
    };

    open();

    return new Promise((resolve, reject) => {
      const onResult = (err: any, data: any = null) => {
        window.removeEventListener('message', handleFinchAuth);
        if (err) {
          return reject(err);
        }
        resolve(data);
      };

      function handleFinchAuth(event: any) {
        if (!event.data) return;
        if (event.data.name !== FINCH_AUTH_MESSAGE_NAME) return;
        if (!event.origin.startsWith(BASE_FINCH_CONNECT_URI)) return;

        const { code, error, closed } = event.data;

        close();
        if (code) onResult(null, { code });
        else if (error) onResult(error);
        else if (closed) onResult(null);
      }

      window.addEventListener('message', handleFinchAuth);
    });
  },
};
