import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { format, parse } from 'url';
import { useSelector } from 'react-redux';
import { Company, ProcessStatus, OnboardingStatus } from '@hum/types';
import { Routes, NoticeName, AppState } from '@hum/icm-app/src/state';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { useIsWhitelabeled } from '@hum/icm-app/src/hooks/useIsWhitelabeled';
import { useIsSvbSubDomain } from '@hum/icm-app/src/hooks/useIsSvbSubdomain';
import { useCompanyNotice } from '@hum/icm-app/src/hooks/useCompanyNotice';

const isOnboardingCompleted = (company: any) => {
  return [
    OnboardingStatus.Complete,
    OnboardingStatus.LegacyComplete,
    OnboardingStatus.CompleteButWaitingForMdaToBeDownloaded,
  ].includes(company?.data?.application?.onboardingStatus);
};

const {
  token: _token,
  name: _name,
  company: _company,
  action: _action,
  returnTo: _returnTo,
  ...otherParams
} = parse(window.location.href, true).query;

export const Gateway = ({}) => {
  const company = useCurrentCompany();
  const { data: currentUser } = useSelector((state: AppState) => state.session);
  const history = useHistory();
  const urlQuery = format({ query: otherParams });

  return (
    <OnboardingV5
      company={company}
      user={currentUser}
      history={history}
      urlQuery={urlQuery}
    />
  );
};

export const OnboardingV4 = ({ company, history, urlQuery }) => {
  const isBranded = useIsWhitelabeled();
  const isSvb = useIsSvbSubDomain();

  useEffect(() => {
    if (company?.data?.application?.onboardingStatus) {
      if (isOnboardingCompleted(company)) {
        if (isBranded) {
          history.push(`${Routes.COMPANY_ACTIVATION_CONNECTORS}${urlQuery}`);
        } else {
          history.push(`${Routes.COMPANY_ACTIVATION_GET_STARTED}${urlQuery}`);
        }
      } else {
        if (isSvb) {
          history.push(`${Routes.ONBOARDING_V4_BASIC_INFO}${urlQuery}`);
        } else {
          history.push(`${Routes.ONBOARDING_V4_MNDA}${urlQuery}`);
        }
      }
    }
  }, [company?.data?.application?.onboardingStatus]);

  return null;
};

export const OnboardingV5 = ({ company, user, history, urlQuery }) => {
  const currentCompany: Company = company.data;
  const [
    chooseYourAdventureOnboardingCompleted,
    chooseYourAdventureOnboardingCompletedLoaded,
  ] = useCompanyNotice(
    NoticeName.ChooseYourAdventureOnboardingCompleted,
    company.data?.id
  );

  useEffect(() => {
    if (!company?.loaded || !user) {
      return;
    }
    // wait for onboarding v5 – choose your adventure
    if (!chooseYourAdventureOnboardingCompletedLoaded) {
      return;
    }

    if (company?.data?.application?.onboardingStatus) {
      if (isOnboardingCompleted(company)) {
        history.push(`${Routes.COMPANY_ACTIVATION_GET_STARTED}${urlQuery}`);
        return;
      }
    }

    if (
      currentCompany.application.reviewAndConfirmNeeded &&
      !currentCompany.application.reviewAndConfirmOn
    ) {
      history.push(`${Routes.REVIEW_AND_CONFIRM}${urlQuery}`);
    } else if (!currentCompany.signupStatus.hasBasicBusinessInfo) {
      history.push(`${Routes.ABOUT_YOUR_BUSINESS}${urlQuery}`);
    } else if (!currentCompany.signupStatus.hasFundraisingHistory) {
      history.push(`${Routes.SHARE_YOUR_GOALS}${urlQuery}`);
    } else if (!currentCompany.signupStatus.hasFundaisingGoals) {
      history.push(`${Routes.FUNDRAISING_HISTORY}${urlQuery}`);
    } else if (!user.verifiedEmail) {
      history.push(`${Routes.COMPLETE_SIGNUP}${urlQuery}`, {
        signUpEmail: user.email,
      });
    } else if (currentCompany.signupStatus.complete) {
      if (chooseYourAdventureOnboardingCompleted) {
        history.push(`${Routes.COMPANY_ACTIVATION_GET_STARTED}${urlQuery}`);
        return;
      }
      if (currentCompany.processStatus === ProcessStatus.ReadyForSyndication) {
        history.push(`${Routes.COMPANY_ACTIVATION_GET_STARTED}${urlQuery}`);
      } else {
        history.push(`${Routes.ONBOARDING_V5_OVERVIEW}${urlQuery}`);
      }
    }
  }, [company?.loaded, chooseYourAdventureOnboardingCompletedLoaded]);

  return null;
};
