// @ts-ignore
import { env } from '@hum/common';
import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { client as backend } from '@hum/common/src/api/client';
import { AvailableServiceInfo } from './base';
import { openOAUthWindow2 } from '@hum/icm-app/src/utils';

const xeroOauthUrl = async () => {
  try {
    return await backend.get(`/auth/xero/transaction`);
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
  }
};

const xeroDirectUrl = async (clientId: string, clientSecret: string) => {
  try {
    let params = new URLSearchParams();
    params.append('client_id', clientId);
    params.append('client_secret', clientSecret);
    let request = { params };
    return await backend.get(`/auth/xero/direct_login`, request);
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
  }
};

export const xeroOauthConnector: AvailableServiceInfo = {
  label: 'Xero',
  icon: require('./xero.svg'),
  icon2: require('@hum/figma/components/logo/xero@1.svg'),
  type: ConnectorType.Xero,
  source: ConnectorSource.Capital,
  isBeta: true,
  async connect() {
    const oauthUrl = await xeroOauthUrl();
    const { code, state } = await openOAUthWindow2(oauthUrl);
    return {
      url: oauthUrl,
      code,
      state,
    };
  },
};

export const xeroDirectConnector: AvailableServiceInfo = {
  label: 'Xero',
  icon: require('./xero.svg'),
  icon2: require('@hum/figma/components/logo/xero@1.svg'),
  type: ConnectorType.Xero,
  source: ConnectorSource.Capital,
  helpLinkFeatureFlag: 'show-xero-direct-connector-help-link',
  fields: [
    {
      label: 'Client ID',
      propertyName: 'client_id',
    },
    {
      label: 'Client Secret',
      propertyName: 'client_secret',
    },
  ],
  helpFindLink:
    'https://info.humcapital.com/hum-help-center/how-to-log-in-with-your-xero-credentials-1',
  validate({ client_id, client_secret }) {
    return Boolean(client_id && client_secret);
  },
  async connect({ client_id, client_secret }) {
    const newOauthUrl = await xeroDirectUrl(client_id, client_secret);
    const { code, state } = await openOAUthWindow2(newOauthUrl);

    return { url: newOauthUrl, code, state, client_id, client_secret };
  },
};
