import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const kizenDirectConnector: AvailableServiceInfo = {
  label: 'Kizen',
  icon: require('./kizen.svg'),
  isBeta: true,
  type: ConnectorType.Kizen,
  source: ConnectorSource.Capital,
  fields: [
    {
      label: 'Enter your API Key',
      propertyName: 'api_key',
    },
    {
      label: 'Enter your User Id',
      propertyName: 'user_id',
    },
    {
      label: 'Enter your Business Id',
      propertyName: 'business_id',
    },
  ],
  connect: (params) => params,
};
