import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const fastbillDirectConnector: AvailableServiceInfo = {
  type: ConnectorType.Fastbill,
  source: ConnectorSource.Capital,
  label: 'Fastbill',
  icon: require('./fastbill.svg'),
  isBeta: true,
  fields: [
    {
      label: 'Enter your Email',
      propertyName: 'email',
    },
    {
      label: 'Enter your API Key',
      propertyName: 'api_key',
    },
  ],
  connect: (params) => params,
};
