// Components
import ConnectOauth from '../common';
import { ConnectorType } from '@hum/icm-app/src/state';

const getOAuthCodeParams = (
  code: string,
  { shop_name }: Record<string, string>
): Record<string, string> => {
  return {
    code,
    subdomain: shop_name,
  };
};

export const ConnectShopify = ConnectOauth(
  ConnectorType.Shopify,
  getOAuthCodeParams
);
