import React from 'react';
import { Icon } from '@hum/ui-library';
import { Step } from './Step';
import { redirectHandler, Routes } from '@hum/icm-app/src/state';
import { useHistory } from 'react-router-dom';

export function NextSteps() {
  const history = useHistory();

  return (
    <div data-testid="company-dashboard::next-steps">
      <Step header title="Next Steps" />
      <Step
        title="Connect accounting system"
        description="Connecting your accounting software will unlock exclusive funding opportunities."
        icon={<Icon.ChevronRight />}
        testId="company-dashboard::next-steps::accounting-system"
        onClick={redirectHandler(
          Routes.COMPANY_ACTIVATION_CONNECTORS_ACCOUNTING,
          history
        )}
      />
      <Step
        title="Connect payment processors"
        description="Connecting a payment processor allows investors to make a decision about financing your company faster and easier."
        icon={<Icon.ChevronRight />}
        testId="company-dashboard::next-steps::payment-processor"
        onClick={redirectHandler(
          Routes.COMPANY_ACTIVATION_CONNECTORS_PAYMENT,
          history
        )}
      />
      <Step
        title="Connect bank account"
        description="Complete your funding profile by connecting your bank account. Hum keeps your business data secure over traditional sharing methods like email."
        icon={<Icon.ChevronRight />}
        testId="company-dashboard::next-steps::bank-account"
        onClick={redirectHandler(
          Routes.COMPANY_ACTIVATION_CONNECTORS_BANK,
          history
        )}
      />
      <Step
        title="Upload your files"
        description="Present a well-rounded picture of your business to investors by uploading your pitch deck or product demos."
        icon={<Icon.ChevronRight />}
        testId="company-dashboard::next-steps::data-room"
        onClick={redirectHandler(
          Routes.COMPANY_ACTIVATION_DOCUMENT_DATAROOM,
          history
        )}
      />
    </div>
  );
}
