import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const threeDCartDirectConnector: AvailableServiceInfo = {
  label: '3dcart',
  icon: require('./3dcart.svg'),
  isBeta: true,
  type: ConnectorType.ThreeDCart,
  source: ConnectorSource.Capital,
  fields: [
    {
      label: 'Enter your Token',
      propertyName: 'token',
    },
    {
      label: 'Enter your Secure URL',
      propertyName: 'secure_url',
    },
    {
      label: 'Enter your Private Key',
      propertyName: 'private_key',
    },
  ],
  connect: (params) => params,
};
