import React from 'react';
import { Text, TextType, Icon } from '@hum/ui-library';
import { redirectHandler, Routes } from '@hum/icm-app/src/state';
import { useHistory } from 'react-router';

export function MediaCard({ insights }: { insights: boolean }) {
  const IconContainer = insights
    ? 'px-0'
    : 'px-8 flex items-center justify-center';
  const history = useHistory();

  return (
    <div
      className="grid grid-cols-1 border border-borderColor bg-surface-subdued mb-10 rounded-default sm:grid-cols-2 md:grid-cols-3"
      data-testid="company-dashboard::media-card"
    >
      <div
        className={`${IconContainer} border-r border-borderColor col-span-1 py-2 md:py-0`}
      >
        {insights ? (
          <img
            src="https://assets.humcapital.com/explore-insights.png"
            className="lg:object-fill object-contain h-full my-0 mx-auto"
          />
        ) : (
          <Icon.HumConnect />
        )}
      </div>
      <div className="py-14 px-8 col-span-1 md:col-span-2">
        <Text type={TextType.HEADING_M}>
          {insights
            ? 'Explore your business insights'
            : 'Sit tight. We’re reviewing your data.'}
        </Text>
        <Text type={TextType.BODY_S} subdued className="mt-1">
          {insights
            ? 'Navigate Hum generated insights that will help you create a robust fundraising story for investors.'
            : 'This process typically takes around 24 hours. We’ll let you know when it’s ready! In the meantime, get your profile ready by following the next steps.'}
        </Text>
        {insights && (
          <div className="flex items-end mt-2">
            <Icon.Chart className="mr-1" />
            <a
              onClick={redirectHandler(Routes.INSIGHTS, history)}
              className="ui-link"
            >
              Explore Insights
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
