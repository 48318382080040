// Libraries
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from '@hum/icm-app/src/state';
// Components
import { Spinner } from '@hum/ui-library';
import { OnboardingContent } from '@hum/icm-app/src/pages/onboarding-v5/styles/layout.pc';
import { useRouter } from '@hum/icm-app/src/hooks/useRouter';
import { ErrorModal } from '@hum/icm-app/src/pages/onboarding-v5/components/OnboardingConnectStep_DeprecateAfterM2_5/ErrorModal';

const QuickbooksIcon = require('@hum/figma/components/logo/quickbooks@3.png');

// Screen designed to help a user ConnectQuickbooks their account
export const ConnectQuickbooks = () => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const {
    query: { code },
  } = useRouter<{ code?: string }>();

  const submitToken = () => {
    setLoading(false);
    if (code) {
      setSuccess(true);
    }
  };

  useEffect(() => {
    submitToken();
    // eslint-disable-next-line
  }, []);

  return (
    <OnboardingContent centered>
      {loading && <Spinner fullScreen />}
      {success && 'Success'}
      {!loading && !success && (
        <ErrorModal
          serviceName="Quickbooks"
          serviceLogo={QuickbooksIcon}
          visible
          onCancel={() => {
            window.close();
          }}
          onTryAgain={() => {
            history.push(Routes.ONBOARDING_V5_CONNECT);
          }}
        />
      )}
    </OnboardingContent>
  );
};
