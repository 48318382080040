import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { env } from '@hum/common';
import { AvailableServiceInfo } from './base';
import { stringify } from 'query-string';
import { openOAUthWindow2 } from '@hum/icm-app/src/utils';

const baseUrl = 'https://my.freshbooks.com/service/auth/oauth/authorize';

const sandboxClientId =
  '759b404b2b7926f091c7a7152188d972ce8ce9911f118e28c862f21cc5ffa19c';

const config = {
  client_id: env.NEXT_STATIC_FRESHBOOKS_CLIENT_ID || sandboxClientId,
  response_type: 'code',
  redirect_uri: env.NEXT_STATIC_FRESHBOOKS_REDIRECT_URI,
};

export const freshbooksOAuthConnector: AvailableServiceInfo = {
  label: 'FreshBooks',
  isBeta: true,
  icon: require('./freshbooks.svg'),
  type: ConnectorType.Freshbooks,
  source: ConnectorSource.Capital,
  async connect() {
    const oauthUrl = `${baseUrl}?${stringify(config)}`;
    return await openOAUthWindow2(oauthUrl);
  },
};
