import React, { useEffect } from 'react';
import { stringify } from 'query-string';
// @ts-ignore
import { nanoid } from 'nanoid';
import { env } from '@hum/common';
import { useRouter } from '@hum/icm-app/src/hooks/useRouter';
import { Welcome } from '@hum/icm-app/src/components/Welcome';
import { getLoginRedirectPath } from '@hum/icm-app/src/state';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';

const sandboxClientId = 'ca_GIKG1HtLCPDmVWRPp29ASfrkYhx9Piod';
const scopePermissions = [
  'read_products',
  'read_product_listings',
  'read_customers',
  'read_orders',
  'read_inventory',
  'read_fulfillments',
  'read_analytics',
];
const config = {
  client_id: env.NEXT_STATIC_SHOPIFY_CLIENT_ID || sandboxClientId,
  response_type: 'code',
  redirect_uri: `${window.location.origin}/connect/shopify/`,
  nonce: nanoid(),
  scope: scopePermissions.join(','),
};

// for testing, use: captecio-test
const getOauthUrl = (shop: string) =>
  `https://${shop}/admin/oauth/authorize?${stringify(config)}`;

// yarn
export const ShopifyInstallRedirect = () => {
  const router = useRouter<{ shop?: string; token: string }>();

  const { state } = useAppStore();
  const currentUser = state.session.data;

  useEffect(() => {
    if (!currentUser?.id) {
      if (router.query.token !== undefined) {
        // TODO: this eventually needs to be moved into sagas. (CC)
        router.push({
          pathname: '/invite',
          query: router.query,
        });
      } else {
        // TODO: this eventually needs to be moved into sagas. (CC)
        router.push(
          getLoginRedirectPath(
            window.location.pathname + window.location.search
          )
        );
      }
    }

    // captec.io/pages/connect/shopify/install?shop=captecio-test.myshopify.com
    if (window && router && router.query && router.query.shop) {
      window && window.location.assign(getOauthUrl(router.query.shop));
    }
  }, [router, currentUser]);

  return <Welcome title="Redirecting to Shopify..." subtitle="" />;
};
