import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const agileCRMDirectConnector: AvailableServiceInfo = {
  label: 'AgileCRM',
  icon: require('./agilecrm.svg'),
  isBeta: true,
  type: ConnectorType.AgileCRM,
  source: ConnectorSource.Capital,
  fields: [
    {
      label: 'Enter your Username',
      propertyName: 'username',
    },
    {
      label: 'Enter your API Token',
      propertyName: 'api_token',
    },
  ],
  connect: (params) => params,
};
