import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';
import { env } from '@hum/common';
import { stringify } from 'query-string';
import { openOAUthWindow2 } from '@hum/icm-app/src/utils';

const baseUrl = 'https://connect.stripe.com/oauth/authorize';
const sandboxClientId = 'ca_GIKG1HtLCPDmVWRPp29ASfrkYhx9Piod';

const config = {
  client_id: env.NEXT_STATIC_STRIPE_CLIENT_ID || sandboxClientId,
  scope: 'read_only',
  response_type: 'code',
  redirect_uri: `${window.location.origin}/connect/stripe/`,
};

const OAUTH_URL = `${baseUrl}?${stringify(config)}`;

export const stripeConnector: AvailableServiceInfo = {
  label: 'Stripe',
  icon: require('./stripe.svg'),
  type: ConnectorType.Stripe,
  source: ConnectorSource.Capital,
  async connect() {
    const { code } = await openOAUthWindow2(OAUTH_URL);
    return { code };
  },
};

export const stripeTestConnector: AvailableServiceInfo = {
  label: 'Stripe Test',
  icon: require('./stripe.svg'),
  type: ConnectorType.StripeTest,
  source: ConnectorSource.Codat,
  connect: (params) => params,
};
