import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const billomatDirectConnector: AvailableServiceInfo = {
  label: 'Billomat',
  icon: require('./billomat.svg'),
  isBeta: true,
  type: ConnectorType.Billomat,
  source: ConnectorSource.Capital,
  fields: [
    {
      label: `Enter your API Key`,
      propertyName: 'api_key',
    },
  ],
  connect: (params) => params,
};
