import { AvailableServiceInfo } from './base';
import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';

/*

    const [apiKey, password, url] = await prompt([
      `Enter your App API key`,
      `Enter your App Password`,
      `Enter your App Example URL`,
    ]);
*/

/*

    const [
      consumerKey,
      consumerSecret,
      tokenId,
      tokenSecret,
      accountId,
    ] = await prompt([
      `Enter your Consumer Key`,
      `Enter your Consumer Secret`,
      `Enter your Token ID`,
      `Enter your Token Secret`,
      `Enter your Account ID`,
    ]);
*/

// TODO: instructions
export const netsuiteDirectConnector: AvailableServiceInfo = {
  label: 'NetSuite',
  icon: require('./netsuite.svg'),
  icon2: require('@hum/figma/components/logo/netsuite@1.svg'),
  type: ConnectorType.Netsuite,
  source: ConnectorSource.Capital,
  helpFindLink:
    'https://drive.google.com/file/d/16hMyQmJ7U_1zVuwqUbnuCzWxt9fZ7UbR/view?usp=sharing',
  fields: [
    {
      label: 'Enter your Consumer Key',
      propertyName: 'consumer_key',
    },
    {
      label: 'Enter your Consumer Secret',
      propertyName: 'consumer_secret',
    },
    {
      label: 'Enter your Token ID',
      propertyName: 'token_id',
    },
    {
      label: 'Enter your Token Secret',
      propertyName: 'token_secret',
    },
    {
      label: 'Enter your Account ID',
      propertyName: 'account_id',
    },
  ],
  connect: (params) => params,
};
