import { AvailableServiceInfo } from './base';
import { nanoid } from 'nanoid';
import { env } from '@hum/common';
import { openOAUthWindow2 } from '@hum/icm-app/src/utils';
import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { stringify } from 'query-string';
import { getUrlForConnectorsRedirection } from './connectorsUtils';

const sandboxClientId = '4e5b94c811486d768f65d8f62aa7bcda';
const scopePermissions = [
  'read_products',
  'read_product_listings',
  'read_customers',
  'read_orders',
  'read_inventory',
  'read_fulfillments',
];
const config = {
  client_id: env.NEXT_STATIC_SHOPIFY_CLIENT_ID || sandboxClientId,
  response_type: 'code',
  redirect_uri: `${getUrlForConnectorsRedirection()}/connect/shopify/`,
  nonce: nanoid(),
  scope: scopePermissions.join(','),
};

export const getOAuthCodeParams = ({
  code,
  shop_name,
}: Record<string, string>): Record<string, string> => {
  return {
    code,
    subdomain: shop_name,
  };
};

const getOAuthUrl = (shopName: string) => {
  return `https://${shopName}.myshopify.com/admin/oauth/authorize?${stringify(
    config
  )}`;
};

export const shopifyOAuthConnector: AvailableServiceInfo = {
  label: 'Shopify',
  icon: require('./shopify.svg'),
  type: ConnectorType.Shopify,
  source: ConnectorSource.Capital,
  fields: [
    {
      label: 'Shop name',
      propertyName: 'shopName',
    },
  ],
  async connect({ shopName }) {
    const oauthUrl = getOAuthUrl(shopName);
    const params = await openOAUthWindow2(oauthUrl);
    return getOAuthCodeParams(params);
  },
};

export const shopifyDirectConnector: AvailableServiceInfo = {
  label: 'Shopify',
  icon: require('./shopify.svg'),
  type: ConnectorType.Shopify,
  source: ConnectorSource.Capital,
  helpFindLink:
    'https://info.humcapital.com/hum-help-center/how_to_connect_to_shopify',
  fields: [
    {
      label: 'Enter your Admin Access token',
      propertyName: 'accessToken',
    },
    {
      label: 'Enter your myshopify.com domain',
      propertyName: 'domain',
    },
  ],
  connect({ accessToken, domain }) {
    return { access_token: accessToken, domain };
  },
};
