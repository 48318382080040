import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const xcartDirectConnector: AvailableServiceInfo = {
  label: 'XCart',
  icon: require('./xcart.svg'),
  type: ConnectorType.Xcart,
  source: ConnectorSource.Capital,
  isBeta: true,
  fields: [
    {
      label: 'Enter your Store Domain',
      propertyName: 'store_domain',
    },
    {
      label: 'Enter your API key',
      propertyName: 'api_key',
    },
  ],
  connect: (params) => params,
};
