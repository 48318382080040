import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const storeHippoDirectConnector: AvailableServiceInfo = {
  label: 'StoreHippo',
  icon: require('./storehippo.svg'),
  isBeta: true,
  type: ConnectorType.StoreHippo,
  source: ConnectorSource.Capital,
  fields: [
    {
      label: 'Enter your Access Key',
      propertyName: 'access_key',
    },
    {
      label: 'Enter your Subdomain',
      propertyName: 'subdomain',
    },
  ],
  connect: (params) => params,
};
