import { AvailableServiceInfo } from './base';
import { openOAUthWindow2 } from '@hum/icm-app/src/utils';
import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { client as backend } from '@hum/common/src/api/client';

const braintreeConnectURL = (companyId: number) => async () => {
  try {
    const result = await backend.get(
      `/companies/${companyId}/connectors/braintree/connect_url`
    );
    return result.connect_url;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
  }
};

export const braintreeOAuthConnector: AvailableServiceInfo = {
  label: 'Braintree',
  icon: require('./braintree.svg'),
  type: ConnectorType.Braintree,
  source: ConnectorSource.Capital,
  fields: [],
  async connect(_params, options) {
    if ('company' in options) {
      const oauthUrl = await braintreeConnectURL(options.company.id)();
      const { merchantId, state, code } = await openOAUthWindow2(oauthUrl);
      return {
        code,
        merchant_id: merchantId,
        state,
      };
    }
    return {
      code: '',
      merchant_id: '',
      state: '',
    };
  },
};

export const braintreeDirectConnector: AvailableServiceInfo = {
  label: 'Braintree',
  icon: require('./braintree.svg'),
  type: ConnectorType.Braintree,
  source: ConnectorSource.Capital,
  helpFindLink:
    'https://articles.braintreepayments.com/control-panel/important-gateway-credentials',
  fields: [
    {
      label: 'Enter your Merchant ID',
      propertyName: 'merchant_id',
    },
    {
      label: 'Enter your Public Key',
      propertyName: 'public_key',
    },
    {
      label: 'Enter your Private Key',
      propertyName: 'private_key',
    },
  ],
  connect: (params) => params,
};
