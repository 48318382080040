import { CodatConnectorType, ConnectorSource } from '@hum/icm-app/src/state';

import { expandConnectUrl } from '@hum/icm-app/src/utils';
import { AvailableServiceInfo } from './base';
import { CodatConnectorTypeValue, getCodatConnectionUrl } from './codatCommon';

export const codatNetsuiteConnector: AvailableServiceInfo = {
  label: 'NetSuite',
  type: CodatConnectorType.Netsuite,
  icon: require('./netsuite.svg'),
  icon2: require(`@hum/figma/components/logo/netsuite@1.svg`),
  source: ConnectorSource.Codat,
  connect: async (_params, options) => {
    if ('companyId' in options) {
      const connectionUrl = await getCodatConnectionUrl({
        companyId: options.companyId,
        type: CodatConnectorTypeValue.Netsuite,
      });

      if (connectionUrl.data?.connectUrl) {
        window.open(expandConnectUrl(connectionUrl.data.connectUrl));
      }

      return connectionUrl;
    }
    return {};
  },
};
