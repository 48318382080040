import React from 'react';
import clsx from 'clsx';
import memoize from 'fast-memoize';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Routes } from '@hum/icm-app/src/state/routes';
import { CompanyType, MenuItem } from '@hum/types';
import { DataRoom } from './DataRoom';
import { ConnectorsPage } from '../ConnectorsPage';
import { SidebarNav, Spinner } from '@hum/ui-library';
import { getFundingOpportunities, useApiQuery } from '@hum/api';
import { SidebarNavItems } from '@hum/icm-app/src/components/SidebarNavItems';
import { useIsWhitelabeled } from '@hum/icm-app/src/hooks/useIsWhitelabeled/';
import { Topbar } from '@hum/icm-app/src/components/Topbar';

const getMenuOptions = memoize(
  (): Record<string, MenuItem> => {
    const obj = {
      [Routes.COMPANY_ACTIVATION_CONNECTORS]: {
        title: 'Connectors',
        testName: 'company-activation:side-bar:connectors',
        component: <ConnectorsPage />,
        requires: [CompanyType.Company, CompanyType.Admin],
      },
      [Routes.COMPANY_ACTIVATION_DOCUMENT_DATAROOM]: {
        title: 'Data room',
        testName: 'company-activation:side-bar:dataroom',
        component: <DataRoom />,
        requires: [CompanyType.Company, CompanyType.Admin],
      },
    };

    return obj;
  }
);

export const CompanyActivationContent = () => {
  const isBranded = useIsWhitelabeled();
  const menuOptions = getMenuOptions();
  const menuItems = Object.entries(menuOptions).filter(([_, item]) =>
    isBranded ? !item.hiddenInBranded : true
  );
  const { isLoading } = useApiQuery(getFundingOpportunities, {
    toastsEnabled: false,
  });

  if (isLoading) {
    return <Spinner fullScreen />;
  }

  return (
    <div data-testid="company-activation:main">
      <SidebarNav fixedBar>
        <SidebarNavItems items={menuItems} />
      </SidebarNav>
      <SidebarNav.Content>
        <Switch>
          {Object.entries(menuOptions).map(
            ([key, menuItem]) =>
              menuItem.component && (
                <Route key={key} path={key}>
                  <div
                    className={clsx('w-full', {
                      'max-w-4xl mx-auto py-10 px-8':
                        menuItem.testName !==
                        'company-activation:side-bar:public-profile',
                    })}
                  >
                    {menuItem.component}
                  </div>
                </Route>
              )
          )}
          <Route path={Routes.COMPANY_ACTIVATION_ROOT}>
            <Redirect
              to={Routes.COMPANY_ACTIVATION_CONNECTORS + window.location.search}
            />
          </Route>
        </Switch>
      </SidebarNav.Content>
    </div>
  );
};

export const CompanyActivation = () => {
  return (
    <>
      <Topbar fixedBar />
      <CompanyActivationContent />
    </>
  );
};
