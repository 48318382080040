import { openOAUthWindow2 } from '@hum/icm-app/src/utils';
import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';
import { env } from '@hum/common';
import { nanoid } from 'nanoid';
import { stringify } from 'query-string';

const baseUrl = 'https://appcenter.intuit.com/connect/oauth2';
const sandboxClientId = 'ABY00Mcy1ge3BR3k8fYYqxgbJfFmM3HsxLi6VxVRCI8PnkT3oJ';

const config = {
  client_id: env.NEXT_STATIC_QUICKBOOKS_CLIENT_ID || sandboxClientId,
  scope: 'com.intuit.quickbooks.accounting',
  response_type: 'code',
  redirect_uri:
    typeof window !== 'undefined' &&
    `${window.location.origin}/connect/quickbooks/`,
};

export const quickbooksConnector: AvailableServiceInfo = {
  label: 'QuickBooks',
  type: ConnectorType.Quickbooks,
  source: ConnectorSource.Capital,
  icon: require('./quickbooks.svg'),
  icon2: require('@hum/figma/components/logo/quickbooks@1.svg'),
  async connect() {
    const oauthUrl = `${baseUrl}?${stringify({
      ...config,
      state: nanoid(13),
    })}`;

    const { code, realmId } = await openOAUthWindow2(oauthUrl);

    return { code, realm_id: realmId };
  },
};
