// @ts-ignore
import { env } from '@hum/common';
import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { client as backend } from '@hum/common/src/api/client';
import { AvailableServiceInfo } from './base';
import { openOAUthWindow2 } from '@hum/icm-app/src/utils';

const googleAnalyticsOauthUrl = async () => {
  try {
    return await backend.get(`/connector/oauth/google/analytics`);
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
  }
};

export const googleAnalyticsOAuthConnector: AvailableServiceInfo = {
  label: 'Google Analytics',
  icon: require('./google_analytics.svg'),
  type: ConnectorType.GoogleAnalytics,
  source: ConnectorSource.Capital,
  isBeta: true,
  async connect() {
    const oauthUrl = await googleAnalyticsOauthUrl();
    const { code, scope } = await openOAUthWindow2(oauthUrl);
    return {
      code,
      scopes: scope,
    };
  },
};
