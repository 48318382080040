import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const companyHubDirectConnector: AvailableServiceInfo = {
  label: 'CompanyHub',
  icon: require('./company_hub.svg'),
  type: ConnectorType.CompanyHub,
  source: ConnectorSource.Capital,
  isBeta: true,
  fields: [
    {
      label: 'Enter your Api Key',
      propertyName: 'api_key',
    },
    {
      label: 'Enter your Domain',
      propertyName: 'domain',
    },
  ],
  connect: (params) => params,
};
