import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const nimbleDirectConnector: AvailableServiceInfo = {
  label: 'Nimble',
  icon: require('./nimble.svg'),
  isBeta: true,
  type: ConnectorType.Nimble,
  source: ConnectorSource.Capital,
  fields: [
    {
      label: 'Enter your API Key',
      propertyName: 'api_key',
    },
  ],
  connect: (params) => params,
};
