import { authorizenetDirectConnector } from './authorizenet';
import { AvailableServiceInfo } from './base';
import { billomatDirectConnector } from './billomat';
import { braintreeOAuthConnector, braintreeDirectConnector } from './braintree';
import { fastbillDirectConnector } from './fastbill';
import { freshbooksOAuthConnector } from './freshbooks';
import { holdedDirectConnector } from './holded';
import { klarnaDirectConnector } from './klarna';
import { mixpanelDirectConnector } from './mixpanel';
import { liftoffDirectConnector } from './liftoff';
import { microsoftDynamicsDirectConnector } from './microsoft_dynamics';
import { paywhirlDirectConnector } from './paywhirl';
import { googleAnalyticsOAuthConnector } from './google_analytics';
import { googleAdsenseOAuthConnector } from './google_adsense';
import { nimbleDirectConnector } from './nimble';
import { affirmDirectConnector } from './affirm';
import { agileCRMDirectConnector } from './agilecrm';
import { mailchimpDirectConnector } from './mailchimp';
import { mxDirectConnector } from './mx';
import { netsuiteDirectConnector } from './netsuite';
import { paypalDirectConnector, paypalOAuthConnector } from './paypal';
import { plaidConnector } from './plaid';
import { quickbooksConnector } from './quickbooks';
import { recurlyDirectConnector } from './recurly';
import { shopifyDirectConnector } from './shopify';
import { squareOAuthConnector } from './square';
import { stripeConnector, stripeTestConnector } from './stripe';
import { worldpayDirectConnector } from './worldpay';
import { xcartDirectConnector } from './xcart';
import { xeroOauthConnector, xeroDirectConnector } from './xero';
import { yodleeDirectConnector } from './yodlee';
import { exactDirectConnector } from './exact';
import { companyHubDirectConnector } from './сompanyhub';
import { swellDirectConnector } from './swell';
import { kizenDirectConnector } from './kizen';
import { threeDCartDirectConnector } from './3dcart';
import { checkbookDirectConnector } from './checkbook';
import { storeHippoDirectConnector } from './storehippo';
import { finchConnector } from './finch';

// new codat services
import { codatNetsuiteConnector } from './codatNetsuite';
import { codatQuickbooksConnector } from './codatQuickbooks';
import { codatQuickbooksDesktopConnector } from './codatQuickbooksDesktop';
import { codatQuickbooksSandboxConnector } from './codatQuickbooksSandbox';

export const AVAILABLE_SERVICES: AvailableServiceInfo[] = [
  braintreeOAuthConnector,
  braintreeDirectConnector,
  stripeConnector,
  stripeTestConnector,
  shopifyDirectConnector,
  quickbooksConnector,
  netsuiteDirectConnector,
  paypalOAuthConnector,
  paypalDirectConnector,
  squareOAuthConnector,
  plaidConnector,
  xeroOauthConnector,
  xeroDirectConnector,
  klarnaDirectConnector,
  mixpanelDirectConnector,
  liftoffDirectConnector,
  googleAnalyticsOAuthConnector,
  googleAdsenseOAuthConnector,
  paywhirlDirectConnector,
  microsoftDynamicsDirectConnector,
  nimbleDirectConnector,
  affirmDirectConnector,
  agileCRMDirectConnector,
  fastbillDirectConnector,
  yodleeDirectConnector,
  freshbooksOAuthConnector,
  authorizenetDirectConnector,
  mxDirectConnector,
  mailchimpDirectConnector,
  billomatDirectConnector,
  recurlyDirectConnector,
  holdedDirectConnector,
  xcartDirectConnector,
  worldpayDirectConnector,
  exactDirectConnector,
  companyHubDirectConnector,
  swellDirectConnector,
  threeDCartDirectConnector,
  storeHippoDirectConnector,
  checkbookDirectConnector,
  kizenDirectConnector,
  finchConnector,
  codatNetsuiteConnector,
  codatQuickbooksConnector,
  codatQuickbooksDesktopConnector,
  codatQuickbooksSandboxConnector,
];
