import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const mxDirectConnector: AvailableServiceInfo = {
  label: 'MX',
  icon: require('./mx.svg'),
  isBeta: true,
  type: ConnectorType.MX,
  source: ConnectorSource.Capital,
  fields: [
    {
      label: 'Enter your API Key',
      propertyName: 'api_key',
    },
    {
      label: 'Enter your Client ID',
      propertyName: 'client_id',
    },
  ],
  connect: (params) => params,
};
