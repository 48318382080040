import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const authorizenetDirectConnector: AvailableServiceInfo = {
  label: 'Authorizenet',
  icon: require('./authorize-net.svg'),
  isBeta: true,
  type: ConnectorType.Authorizenet,
  source: ConnectorSource.Capital,
  fields: [
    {
      label: 'Enter your Auth Transaction Key',
      propertyName: 'auth_transaction_key',
    },
    {
      label: 'Enter your Auth Name',
      propertyName: 'auth_name',
    },
  ],
  async connect(params) {
    return params;
  },
};
