import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const worldpayDirectConnector: AvailableServiceInfo = {
  label: 'Worldpay',
  icon: require('./worldpay.svg'),
  type: ConnectorType.Worldpay,
  source: ConnectorSource.Capital,
  isBeta: true,
  fields: [
    {
      label: 'Enter your Service Key',
      propertyName: 'service_key',
    },
    {
      label: 'Enter your Merchant ID',
      propertyName: 'merchant_id',
    },
  ],
  connect: (params) => params,
};
