import React from 'react';
import styled from 'styled-components';

import { useDashboard, greetingTime } from '.';

export const StyledWelcome = styled.section`
  width: 100%;
  height: 208px;
  margin-top: var(--spacing-9);
  background-color: var(--brand-brand-20);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  font-family: 'Supera Gothic';
  font-weight: bold;
  user-select: none;
  padding: 0;

  h1 {
    font-size: 32px;
    color: var(--color-black-default);
  }

  p {
    font-size: 32px;
    color: var(--brand-hum-blue);
  }

  @media (max-width: 600px) {
    padding: 0 var(--spacing-7);
    height: 260px;
    white-space: pre-wrap;
    box-sizing: border-box;
    text-align: center;
  }
`;

export const DashboardHeader = () => {
  const { company } = useDashboard();

  return (
    <StyledWelcome>
      <h1>{greetingTime()}</h1>
      <p>{company.data?.name}.</p>
    </StyledWelcome>
  );
};
