import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const exactDirectConnector: AvailableServiceInfo = {
  label: 'Exact',
  icon: require('./exact.svg'),
  isBeta: true,
  type: ConnectorType.Exact,
  source: ConnectorSource.Capital,
  fields: [
    {
      label: 'Enter your Client Id',
      propertyName: 'client_id',
    },
    {
      label: 'Enter your Client Secret',
      propertyName: 'client_secret',
    },
  ],
  connect: (params) => params,
};
