import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';

export const mailchimpDirectConnector: AvailableServiceInfo = {
  label: 'Mailchimp',
  icon: require('./mailchimp.svg'),
  isBeta: true,
  type: ConnectorType.Mailchimp,
  source: ConnectorSource.Capital,
  fields: [
    {
      label: 'Enter your API Key',
      propertyName: 'api_key',
    },
  ],
  connect: (params) => params,
};
