import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as containerStyles from '@hum/icm-app/src/components/Split/styles.pc';
import { useFileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';
import * as dashboard from '../../company-activation/styles.pc';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { MetaTitle } from '@hum/legacy-ui';
import { CompanyFileDocumentType } from '@hum/types';
import { AppState, Routes } from '@hum/icm-app/src/state';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { downloadCompanyFinancialButtonClicked } from '../../syndication-v2/CompanyDetail/ducks/actions';
import { DashboardHeader } from './DashboardHeader';
import { LearningResources } from './LearningResources';
import { NextSteps } from './NextSteps';
import { FooterPill } from './FooterPill';
import { MediaCard } from './MediaCard';
import { InlineNotification, NotificationType, Spinner } from '@hum/ui-library';
import { useSyndicationInsightsCharts } from '@hum/icm-app/src/hooks/useSyndicationInsightsCharts';
import { useNotice } from '@hum/icm-app/src/hooks/useNotice';
import { getFundingOpportunities, useApiQuery } from '@hum/api';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';

const getSmartRaiseNoticeName = (id: string) =>
  'smart-raise-dashboard-notice-' + id;

export const greetingTime = () => {
  const today = new Date();
  const currentHour = today.getHours();

  if (currentHour < 12) {
    return 'Good Morning,';
  } else if (currentHour < 18) {
    return 'Good Afternoon,';
  } else {
    return 'Good Evening,';
  }
};

export const useDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const company = useCurrentCompany();
  const companyId = company.data?.id!;

  const fileManager = useFileManager({
    companyId,
  });

  // should show post analytics state, this depends on the existence of the analytics file
  const chartbookInvestorAnalyticsFile = fileManager.files.find(
    (file) =>
      file.documentType === CompanyFileDocumentType.ChartbookInvestorAnalytics
  );
  const showPostAnalayticsState = Boolean(chartbookInvestorAnalyticsFile);

  const isDownloadingFile = useSelector(
    (state: AppState) => state.isDownloadingCompanyFinancialsFile.status
  );

  const onDownloadFinancialDataClicked = () => {
    dispatch(
      downloadCompanyFinancialButtonClicked({
        companyId,
        docType: CompanyFileDocumentType.ChartbookInvestorAnalytics,
      })
    );
  };
  const {
    isLoading: smartRaiseLoading,
    data: opportunities,
  } = useApiQuery(getFundingOpportunities, { toastsEnabled: false });
  const showcasedOpportunity = opportunities?.showcased;
  const notificationName = !!showcasedOpportunity?.id
    ? getSmartRaiseNoticeName(showcasedOpportunity.id)
    : undefined;
  const {
    seen: smartRaiseNoticeSeen,
    loading: smartRaiseCheckLoading,
    markAsSeen: markSmartRaiseNoticeAsSeen,
  } = useNotice(notificationName, companyId);
  const {
    loading: areChartsLoading,
    data: companyInsights,
  } = useSyndicationInsightsCharts(companyId);
  const showSmartRaiseNotification =
    !smartRaiseCheckLoading &&
    smartRaiseNoticeSeen === false &&
    showcasedOpportunity?.isActive;
  const onSmartRaiseNotificationDismissed = () => {
    markSmartRaiseNoticeAsSeen();
    history.push(Routes.SMARTRAISE);
  };

  return {
    company,
    isDownloadingFile,
    loaded:
      company.data != null &&
      !areChartsLoading &&
      !smartRaiseLoading &&
      !smartRaiseCheckLoading,
    showPostAnalayticsState,
    onDownloadFinancialDataClicked,
    chartbookInvestorAnalyticsFile,
    showSmartRaiseNotification,
    companyInsights,
    onSmartRaiseNotificationDismissed,
    history,
  };
};

export const Dashboard = () => {
  const {
    company,
    loaded,
    showSmartRaiseNotification,
    onSmartRaiseNotificationDismissed,
    companyInsights,
  } = useDashboard();
  const flags = useFlags();

  useEffect(() => {
    //eslint-disable-next-line
    window.location.assign(`${process.env.V2_BASE_URL}/`);
  }, []);

  if (!loaded || !flags.ready) return <Spinner fullScreen />;

  return (
    <>
      <MetaTitle children={`${greetingTime()} ${company.data?.name}`} />
      <Topbar fixedBar />
      <containerStyles.CobrandingLayout>
        <containerStyles.CobrandingRight>
          <DashboardHeader />
          {showSmartRaiseNotification && (
            <div className="mx-auto max-w-[848px] pt-10 px-4 sm:px-0">
              <InlineNotification
                type={NotificationType.NEW}
                arrow
                onClick={onSmartRaiseNotificationDismissed}
              >
                You’re invited to the SmartRaise process! Learn more about this
                opportunity.
              </InlineNotification>
            </div>
          )}
          <dashboard.SharingWrapper chooseYourAdventureVariant>
            <MediaCard insights={companyInsights !== undefined} />
            <NextSteps />
            <LearningResources />
            <FooterPill />
          </dashboard.SharingWrapper>
        </containerStyles.CobrandingRight>
      </containerStyles.CobrandingLayout>
    </>
  );
};

export default Dashboard;
