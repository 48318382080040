import React from 'react';
import styled from 'styled-components';

import { Help } from '@hum/legacy-ui';
import { env } from '@hum/common';
import infoIconSrc from '@hum/assets/icons/info-circle-gray.png';

const StyledFooterPill = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: var(--spacing-10) 0 var(--spacing-8);
`;

export const FooterPill = () => {
  return (
    <StyledFooterPill>
      <Help
        nofilter
        icon={<img src={infoIconSrc} />}
        content={
          <p>
            <a
              href={`mailto:hello@${env.CAPITAL_MAIN_DOMAIN}`}
              className="ui-link"
            >
              Ask a question{' '}
            </a>
            •
            <a
              href={`mailto:feedback@${env.CAPITAL_MAIN_DOMAIN}`}
              className="ui-link"
            >
              {' '}
              Give us feedback
            </a>
          </p>
        }
      />
    </StyledFooterPill>
  );
};
