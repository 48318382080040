import memoize from 'fast-memoize';
import { ConnectorType, ConnectorSource } from '@hum/icm-app/src/state';
import { AvailableServiceInfo } from './base';
import { env } from '@hum/common';
import { EventEmitter } from 'events';

// Pulled from https://github.com/plaid/react-plaid-link/blob/master/src/usePlaidLink.ts
// since the repo doesn't expose any APIs that can be used outside of React.

const PLAID_LINK_STABLE_URL =
  'https://cdn.plaid.com/link/v2/stable/link-initialize.js';

const PUBLIC_KEY = env.NEXT_STATIC_PLAID_PUBLIC_KEY || '';

const ENVIRONMENT =
  env.NEXT_STATIC_CAPITAL_ENV === 'production'
    ? 'production'
    : env.NEXT_STATIC_CAPITAL_ENV === 'staging'
    ? 'development'
    : 'sandbox';

if (ENVIRONMENT === 'production' && !PUBLIC_KEY) {
  throw new Error('You must define NEXT_STATIC_PLAID_PUBLIC_KEY on production');
}

export const plaidConnector: AvailableServiceInfo = {
  label: 'Plaid',
  icon: require('./plaid.svg'),
  type: ConnectorType.Plaid,
  source: ConnectorSource.Capital,
  async connect() {
    try {
      const plaid = await loadPlaid({
        key: PUBLIC_KEY,
        env: ENVIRONMENT,
        product: ['auth', 'transactions', 'assets'],
        clientName: 'Hum Capital',
      });
      const publicToken = await plaid.open();

      return { public_token: publicToken };
    } catch (e) {
      console.error('Error loading Plaid', e);
      throw e;
    }
  },
};

const loadPlaid = memoize(async (config) => {
  await loadScript(PLAID_LINK_STABLE_URL);

  const em = new EventEmitter();

  // ts-disable-next-line
  const plaid = (window as any).Plaid.create({
    ...config,
    onSuccess: (publicToken: string) => {
      em.emit('exit', null, publicToken);
    },
  });

  const onExit = (callback: (...params: any) => void) => {
    em.on('exit', callback);
    return () => {
      em.removeListener('exit', callback);
    };
  };

  return {
    open() {
      return new Promise<string>((resolve, reject) => {
        plaid.open();
        const dispose = onExit((err, result) => {
          dispose();
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        });
      });
    },
  };
});

// Memo is important here so that we don't accidentally load the same script twice. Also returns
// same promise in case load isn't done.
const loadScript = memoize(
  (src: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.onload = () => resolve();
      script.onerror = () => reject();
      script.src = src;
      document.head.appendChild(script);
    });
  }
);
